














































































import API from '@/plugins/axios';
import Vue from 'vue';
import { imageData } from '@/utils/utilsData';
export default Vue.extend({
  name: 'Unit',
  data: () => ({
    imageData: { ...imageData },
    id: '0',
    data: {} as { id: string; selected: boolean },
    fromMaltese: false,
    loading: true,
    fileData: {},
  }),
  computed: {
    goBack() {
      if (this.fromMaltese) {
        return '/maltese';
      } else {
        const search_term = localStorage.getItem('search_term');
        if (search_term) {
          return `/filters?search=${search_term}`;
        } else {
          return `/`;
        }
      }
    },
  },
  methods: {
    async toggleSelected() {
      if (!this.data.selected) {
        await API.post(`/search/select/${this.id}/`);
        this.data.selected = true;
      } else {
        console.log('delete');
        await API.delete(`/search/select/${this.id}/`);
        this.data.selected = false;
      }
    },
  },
  async mounted() {
    this.loading = true;
    this.fromMaltese = this.$route.params.maltese === 'true';
    this.id = this.$route.params.id as string;

    const { data } = await API.get(`/units/${this.id}/`);
    this.data = data;
    this.loading = false;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    if ((this.data as any).file_uuid) {
      const { data } = await API.get(`/pdf-file/${(this.data as any).file_uuid}/`, {
        baseURL: process.env.VUE_APP_API_URL_FILES,
      });
      this.fileData = data;
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */
  },
});
